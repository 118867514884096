import { Device } from '@/types/app';
import { logger } from '@/utils/logger';
import { getOcelotConfig } from 'lib/configs/ocelot';
import { Content, Data } from 'types/content';
import { AdsInjectExceptions, AllowedTypes, InjectionOptions } from './types';
export type Viewport = 'mobile' | 'desktop';

/**
 * Get pixel-height value matched with contentindex from Height-service
 */
export const getHeight = (contentIndex: number, heights: Data): number =>
  (heights && contentIndex && Number.isInteger(heights[contentIndex]) && heights[contentIndex]) || 0;

/**
 * Get sum of children/content heights
 */
export const getSumOfChildrenHeights = (children: Content[], heights: Data) =>
  children.reduce((acc: number, curr: Data) => {
    let height = 0;
    const type = curr?.type;
    if (type === 'columns' || type === 'row') {
      // Get heights recursively from rows and columns
      height = getSumOfChildrenHeights(curr?.children, heights);
    } else {
      height = getHeight(curr?.meta.contentIndex, heights);
    }
    return acc + height;
  }, 0);

/**
 * Creates an adunit
 *
 * @param {}
 */

const adUnit = (elId: string, placement: string, adsUniqueId: string): Content => {
  const tagId = `${elId}-${adsUniqueId}`;

  return {
    type: 'adunit',
    meta: {
      width: {
        desktop: 100,
        mobile: 100,
      },
    },
    data: {
      elID: tagId,
      placement: placement,
    },
    children: [],
  };
};

export interface InjectionPoint {
  index: number;
  childIndex: number;
  indexLevel: Data;
}

const getTarget = (placementName?: string, serialNo?: number) => {
  if (!placementName) {
    log(`Ad getTarget: No placementName`);
  }
  const postFix = serialNo ? `_${serialNo}` : '';
  return `${placementName}${postFix}`;
};

// Placement X is used for serialNo > 8
// respontive_X and intext_ad_X can be found in adsConfig from
// ex elle: https://se-ads-microservice.ads.allermedia.io/api/v1/config/getelle.se
const setPlacementXForSerialNo9OrHigher = (placementName?: string, serialNo?: number) => {
  if (placementName && serialNo) {
    if (serialNo > 8 && (placementName === 'responsive' || placementName === 'intext')) {
      return `${placementName}_X`;
    }
  }
};

/**
 * Sets the ad-placement (div-attribute) and the target (div-id) for the ad
 * @param placementName {String} Name of the type af ad placement
 * @param serialNo {Number} Serial Number to use with type of placement {placementName}
 * @param route {Array<string>} Array that holds the path-parts of the page
 * @returns {Object}
 */

const resolvePlacementAndTarget = (placementName?: string, serialNo?: number) => {
  // Get Deafult Placement
  let placement = getTarget(placementName, serialNo);
  const divTargetId = placement;

  placement = setPlacementXForSerialNo9OrHigher(placementName, serialNo) || placement;

  log(`resolvePlacementAndTarget ${placement} ${divTargetId}`);

  return { placement, divTargetId };
};

export const getAdUnit = (placementName?: string, adIndex?: number, adsUniqueId: string = '') => {
  const { placement, divTargetId } = resolvePlacementAndTarget(placementName, adIndex);

  log(`Ads Unique id ${adsUniqueId} ${placement} ${divTargetId} `);

  return adUnit(divTargetId, placement, adsUniqueId);
};

export function log(...args: any[]): void {
  if (process.env.OCELOT_AD_DEBUG) logger.info(`Ads:`, args);
}

export const isEmptyObject = (o: any) => Object.keys(o).length === 0 && o.constructor === Object;

export const getHighestNumber = (a: number, b: number) => (a > b ? a : b);

export const getPlacement = (type: string, isNativeArticle: boolean = false) => {
  let placement = type === 'intext' ? 'intext_ad' : 'responsive';

  if (isNativeArticle) {
    placement = type === 'intext' ? 'intext_native' : 'native';
  }
  return placement;
};

const addLeadingZero = (number: number) => (number && number.toString()?.padStart(2, '0')) || `${number}`;

export const getFeedsCount = (content: Content) => {
  const getSumOfFeeds = (content: Content): number =>
    content?.children?.reduce((acc: number, curr: Content) => {
      let count = curr?.meta?.feedId ? 1 : 0;

      if (curr?.children) {
        count += getSumOfFeeds(curr);
      }
      return acc + count;
    }, 0) || 0;

  return getSumOfFeeds(content);
};

export const getCacheBuster = () => {
  const now = new Date();
  const hour = addLeadingZero(now?.getHours());
  const min = addLeadingZero(now?.getMinutes());
  const day = addLeadingZero(now?.getDate());
  const month = addLeadingZero(now?.getMonth());
  const year = now?.getFullYear() || '';
  return `d=${year}${month}${day}${hour}${min}`;
};

// Count Media in a FrontPage-rows for different Content-boxes, structured as below

// *** Fullwidth Boxes
// - row
// - - blogSlider
// - row
// - - affiliateGallery
// - row
// - - newsletterSignup
// - row
// - - mostRead Mest läst just nu

// *** ArticleTeasers in Row
// - row
// - - articleTeaser Nyskapande trähusen i Hagastaden – framtidens miljövänliga boende

// - row
// - - articleTeaser Skapa en vacker jul hemma – 19 idéer till julpyntet
// - - articleTeaser Privat våningsplan till salu i Norra tornen– se bilderna!

// - row
// - - articleTeaser Pella Hedebys favoriter – för en stillsam och vacker jul
// - - articleTeaser Kika in i August Strindbergs gamla villa – som är till salu
// - - articleTeaser 14 utvalda favoriter till bastu och badrum

// *** ArticleTeasers in Columns -> Row
// - row
// - - columns
// - - - row
// - - - - articleTeaser Residence listar utvald julbelysning – 7 favoriter
// - - articleTeaser 15 trendiga rislampor vi drömmer om just nu

// - row
// - - articleTeaser Hemma hos arkitekten – kika in i tvåan med smarta lösningar
// - - columns
// - - - row
// - - - - articleTeaser Residence listar: 15 snygga lampor vi älskar just nu
// - - - - articleTeaser Så väljer du rätt matta till rätt rum

// *** Content in Row -> Box
// - row
// - - box
// - - - articleTeaser
// - - - jwVideo

// *** Content in Row -> columns -> row -> box
// - row
// - - columns
// - - - row
// - - - - box
// - - - - box

export const countMediaForRowInFrontPage = (
  media: any,
  teasersInRowWeight: any,
  singleContentBoxInRowWeight: any,
): number => {
  let mediaCount = 0;

  const firstMediaChild = media.children[0];

  if (media.children.length == 1) {
    // One child (module) inside row
    // ToDo: Filter out un-rendered Content (height = 0 ?)
    mediaCount = getMediaCountForOneChild(media, singleContentBoxInRowWeight);
    log(
      `row -> 1 child `,
      firstMediaChild.type,
      mediaCount,
      firstMediaChild?.data?.content?.substring(0, 30),
      firstMediaChild?.data?.title?.substring(0, 20),
    );
  } else if (media.children.length > 1) {
    // Multiple child (module) inside row

    mediaCount = mediaCountForChildren(media.children, mediaCount, teasersInRowWeight);
  }
  return mediaCount;
};

// Find the content that takes up most height
const mediaCountForChildren = (children: [], mediaCount: number, teasersInRowWeight: number[]): number => {
  children.map((mediaChild: any) => {
    if (mediaChild.type === 'columns') {
      const colFirstChild = mediaChild.children[0];

      if (colFirstChild.type === 'row') {
        // columns -> row -> children

        const colRowCount = teasersInRowWeight[3] * Math.min(colFirstChild.children?.length || 0, 3); // Max 3
        mediaCount = getHighestNumber(colRowCount, mediaCount);

        log(`columns -> row -> children weight:`, mediaCount);
      }
    } else {
      // row -> children

      const rowCount = (children.length && teasersInRowWeight[children.length]) || 0;

      mediaCount = getHighestNumber(rowCount, mediaCount);

      log(`row -> child weight:`, mediaCount, mediaChild.type);
    }
  });
  return mediaCount;
};

const getMediaCountForOneChild = (media: any, singleContentBoxInRowWeight: number[]): number => {
  const firstMediaChild = media.children[0];
  const firstMediaChildWeight = singleContentBoxInRowWeight[firstMediaChild.type] || 0;
  let mediaCount = 0;

  if (
    ['mostRead', 'articleSlider', 'vignette', 'showsSlider', 'banner'].some((type) => firstMediaChild?.type === type)
  ) {
    if (firstMediaChild.children.length > 0) {
      mediaCount = firstMediaChildWeight;
    } else {
      log(`NO COUNT FOR `, firstMediaChild.type, firstMediaChild);
    }
  } else if (firstMediaChild.type === 'newsletterSignup') {
    if (firstMediaChild.children.length > 0 || Object.keys(firstMediaChild?.data || {}).length > 0) {
      mediaCount = firstMediaChildWeight;
    } else {
      log(`NO COUNT FOR `, firstMediaChild.type, firstMediaChild);
    }
  } else if (firstMediaChild.type === 'blogSlider') {
    if (firstMediaChild.data?.bloggers?.length && firstMediaChild.data?.bloggers?.length > 0) {
      mediaCount = firstMediaChildWeight;
    } else {
      log(`NO COUNT FOR `, firstMediaChild.type, firstMediaChild);
    }
  } else if (firstMediaChild.type === 'affiliateGallery') {
    if (firstMediaChild?.data?.gallery) {
      mediaCount = firstMediaChildWeight;
    } else {
      log(`NO COUNT FOR `, firstMediaChild.type, firstMediaChild);
    }
  } else {
    mediaCount = firstMediaChildWeight;
  }
  return mediaCount;
};

/**
 * Adjusts the injectindex when an extra adUnit alreday have been added in current array
 * @param index {Number} Index that might be adjusted
 * @param injectPoint {Object} Holds indexes for where to inject
 * @param injectCounter {Object} Counter for number of injections in content per level
 * @returns {Number}
 */
const adjustInjectIndex = (
  index: number,
  injectPoint: any,
  injectCounter: { root: number; child: Array<number>; tree: Data },
) => {
  // Count injections at tree level
  const fullPath = [injectPoint.index, ...injectPoint.indexLevel];
  const parentPath = fullPath.slice(0, -1);
  const levelStr = parentPath.join('-');
  if (!injectCounter.tree[levelStr]) {
    injectCounter.tree[levelStr] = 0;
  }

  // Adjust injection to previous injections
  const injectIndex = index + injectCounter.tree[levelStr];

  if (injectIndex > index) {
    log(
      ` parentPath: ${parentPath} indexLevel: ${injectPoint.indexLevel} root: ${injectPoint.index} levStr: ${levelStr} count: ${injectCounter.tree[levelStr]} `,
    );
  }

  // Count up previous injections
  injectCounter.tree[levelStr]++;

  return injectIndex;
};
/**
 * Find the content to inject in and the index where to inject
 *
 * @param injectPoint {Object} Holds indexes for where to inject
 * @param content {Object} Content to inject adUnit in
 * @param injectCounter {Object} Counter for number of injections in content per level
 * @returns {Object}
 */
export const resolveInjection = (
  injectPoint: any,
  content: Content,
  injectCounter: { root: number; child: Array<number>; tree: Data },
) => {
  // Set current root index
  const injectAtRoot: number = injectPoint.index + injectCounter.root;
  let contentToInjectIn;
  let injectIndex = injectAtRoot;

  if (Array.isArray(injectPoint.indexLevel) && injectPoint.indexLevel.length > 0) {
    // Injection when using rendered-heights

    contentToInjectIn = content.children[injectAtRoot];

    for (let i = 0; i < injectPoint.indexLevel.length; i++) {
      const index = injectPoint.indexLevel[i];

      if (i === injectPoint.indexLevel.length - 1) {
        // Use index for child at bottom of tree
        injectIndex = adjustInjectIndex(index, injectPoint, injectCounter);

        log(`injectIndex: ${injectIndex} - ${contentToInjectIn?.type} -- ${contentToInjectIn?.data?.title} `);
      } else if (contentToInjectIn.children[index]) {
        // Find contentToInjectIn in children
        contentToInjectIn = contentToInjectIn.children[index];
      }
    }
  } else if (injectPoint.childIndex >= 0) {
    // Injection between media-childs in "row"-content for Articles

    // Count injections
    if (!injectCounter.child[injectPoint.index]) {
      injectCounter.child[injectPoint.index] = 0;
    }

    // Adjust injection to previous injections
    const injectAtRow: number = injectPoint.childIndex + injectCounter.child[injectPoint.index];

    // Count up previous injections
    injectCounter.child[injectPoint.index]++;

    // Set child to be the content to inject in
    contentToInjectIn = content.children[injectAtRoot];
    injectIndex = injectAtRow;
  } else {
    // Injection between childs in root of the content
    contentToInjectIn = content;
    injectIndex = injectAtRoot;
    injectCounter.root++;
  }
  // Inject after index-point (+1)
  injectIndex++;

  log(`injectIndex ${injectIndex}`);

  return { contentToInjectIn, injectIndex };
};

/**
 * Calculates if an ad should be injected at current row/index
 *
 * @param currentRow {number} Current index to evaluate
 * @param frequency {number} After how many rows/indexes should ad be injected,
 * @param firstAdRow {number} Where first ad should be injected
 * @returns {Boolean}
 */

interface shouldInjectAdAtRowProps {
  currentRow: number;
  frequency?: number;
  firstAdRow?: number;
}

export const shouldInjectAdAtRow = ({ currentRow, frequency = 2, firstAdRow = 0 }: shouldInjectAdAtRowProps) => {
  const notBeforeFirstAd = firstAdRow <= currentRow;
  const adFrequencyMatch = (currentRow - firstAdRow) % frequency === 0;
  return notBeforeFirstAd && adFrequencyMatch;
};

export const parseHeights = (heights: Data, device: Device) => {
  const deviceHeights = heights && (device === 'mobile' ? heights.mob?.h : heights.desk?.h);
  // Save heights if not empty or resolve to false
  return deviceHeights && !isEmptyObject(deviceHeights) && deviceHeights;
};

export const includesContainer = (parent: Content) =>
  ['row', 'box', 'affiliateItem'].some((c) => c === parent.type || c === parent?.children[0]?.type);

export const isHeader = (type: string, text: string) => ALLOWED_TYPES.headers.includes(type) && !text.startsWith('<p>');

export const ALLOWED_TYPES: AllowedTypes = {
  media: {
    front: [
      'articleTeaser',
      'blogSlider',
      'newsletterSignup',
      'affiliateGallery',
      'mostRead',
      'columns',
      'markup',
      'coverBox',
      'factbox',
      'richtextbox',
      'articleSlider',
      'showsSlider',
      'vignette',
      'banner',
      'direktTeaser',      
      'videoReels',      
    ],
    article: [
      'labradorImage',
      'jwVideo',
      'banner',
      'affiliateItem',
      'instagram',
      'twitter',
      'tiktok',
      'facebook',
      'youtube',
      'direktTeaser',      
      'videoReels',      
    ],
  },
  headers: ['heading'],
  text: ['paragraph', 'factbox'],
};

export const getInjectSettings = (adsConfig: Data, pageType: string, device: Device, maxNoAds: number) => {
  let options: InjectionOptions,
    startMediaCountFront: number = 0,
    teasersInRowWeight: number[] = [],
    contentBoxInRowWeight: { [key: string]: number } = {};

  const commonOptions = {
    debugName: `ADS ${pageType}`,
    adsToInject: maxNoAds,
    startInsertNode: 1,
  };

  if (pageType === 'front') {
    const fc: any = adsConfig?.frontPageAd || {};

    startMediaCountFront = parseInt(device === 'mobile' ? fc.startMediaCountMob || 300 : fc.startMediaCount || 500);
    options = {
      ...commonOptions,
      charPerImageHeavy: 1,
      heightBefore: {
        mobile: parseInt(fc.heightBeforeMob || 500),
        desktop: parseInt(fc.heightBeforeDesk || 600),
      },
      textBefore: {
        mobile: parseInt(fc.textBeforeMob || 890),
        desktop: parseInt(fc.textBeforeDesk || 900),
      },
    };
    const frontPageWeights = getWeightsForFrontpage(fc, device);
    teasersInRowWeight = frontPageWeights.teasersInRow;
    contentBoxInRowWeight = frontPageWeights.contentBoxInRow;
  } else {
    const ic: any = adsConfig?.intext || {};
    options = {
      ...commonOptions,
      charPerImageHeavy: parseInt(ic.imageHeavyCharPerImage || 300),
      textImageRatioThreshold: 600,
      charPerImageText: parseInt(ic.charPerImage || 200),
      textBefore: {
        mobile: parseInt(ic.textBeforeMob || 600),
        desktop: parseInt(ic.textBeforeDesk || 900),
      },
      textAfter: {
        mobile: parseInt(ic.textAtTheEndMob || 50),
        desktop: parseInt(ic.textAtTheEndDesk || 100),
      },
      imageBefore: {
        mobile: parseInt(ic.imageBeforeMob || 50),
        desktop: parseInt(ic.imageBeforeDesk || 100),
      },
      imageAfter: {
        mobile: parseInt(ic.imageAfterMob || 50),
        desktop: parseInt(ic.imageAfterDesk || 100),
      },
    };
  }

  log(`getConfig options: `, options);

  return { startMediaCountFront, options, teasersInRowWeight, contentBoxInRowWeight };
};

// params fc = frontPage-config
const getWeightsForFrontpage = (fc: any, device: Device) => {
  // Count weight of Media / ContentBoxes

  // Weight in array-position corresponds to amount in the row,
  // For instance 2 teasers in a row on desktop has rowWeight.desktop[2] = 5 as media-weight
  const rowWeight: { [key: string]: Array<number> } = {
    desktop: [
      0,
      parseInt(fc.oneTeaserDesk || 400),
      parseInt(fc.twoTeasersDesk || 500),
      parseInt(fc.threeTeasersDesk || 400),
      parseInt(fc.fourTeasersDesk || 400),
    ],
    mobile: [
      0,
      parseInt(fc.oneTeaserMob || 300),
      parseInt(fc.twoTeasersMob || 600),
      parseInt(fc.threeTeasersMob || 700),
      parseInt(fc.fourTeasersMob || 400),
    ],
  };
  const teasersInRow = rowWeight[device];

  const contentBoxes: { [key: string]: {} } = {
    desktop: {
      articleTeaser: teasersInRow[1],
      affiliateGallery: parseInt(fc.affiliateGalleryDesk || 300),
      newsletterSignup: parseInt(fc.newsLetterSignupDesk || 200),
      mostRead: parseInt(fc.mostReadDesk || 200),
      blogSlider: parseInt(fc.blogSliderDesk || 100),
      jwVideo: parseInt(fc.jwVideo || 300),
      coverBox: parseInt(fc.coverBoxDesk || 300),
      markup: parseInt(fc.markupDesk || 300),
      factbox: parseInt(fc.factboxDesk || 300),
      richtextbox: parseInt(fc.richtextboxDesk || 300),
      articleSlider: parseInt(fc.articleSliderDesk || 400),
      showsSlider: parseInt(fc.showsSliderDesk || 600),
      vignette: parseInt(fc.vignetteDesk || 300),
      banner: parseInt(fc.bannerDesk || 300),
      direktTeaser: parseInt(fc.direktTeaser || 300),
      videoReels: parseInt(fc.videoReels || 300),      
    },
    mobile: {
      articleTeaser: teasersInRow[1],
      affiliateGallery: parseInt(fc.affiliateGalleryMob || 300),
      newsletterSignup: parseInt(fc.newsLetterSignupMob || 300),
      mostRead: parseInt(fc.mostReadMob || 300),
      blogSlider: parseInt(fc.blogSliderMob || 100),
      jwVideo: parseInt(fc.jwVideo || 150),
      coverBox: parseInt(fc.coverBoxMob || 300),
      markup: parseInt(fc.markupMob || 300),
      factbox: parseInt(fc.factboxMob || 300),
      richtextbox: parseInt(fc.richtextboxMob || 300),
      articleSlider: parseInt(fc.articleSliderMob || 300),
      showsSlider: parseInt(fc.showsSliderMob || 300),
      vignette: parseInt(fc.vignetteMob || 300),
      direktTeaser: parseInt(fc.direktTeaser || 300),
      banner: parseInt(fc.bannerMob || 300),
      videoReels: parseInt(fc.videoReels || 300),
    },
  };
  const contentBoxInRow = contentBoxes[device] || 0;

  return { teasersInRow, contentBoxInRow };
};

const exceptionPaths = Object.entries(getOcelotConfig('ads.exceptionPaths'));

export const getExceptions = (route: string[]): Partial<AdsInjectExceptions> | undefined => {
  const exception = exceptionPaths.find(([path]) => new RegExp(path).test(route.join('/')));
  return exception ? (exception[1] as Partial<AdsInjectExceptions>) : undefined;
};

const slashWrapStrip = (s: string) => s.split('/').filter((p) => p && p !== '');

export const loadAdsInjectExceptions = (url?: string): AdsInjectExceptions => {
  const exceptions = url && getExceptions(slashWrapStrip(url.split('?')[0]));
  const { aboveBody = false, extraStartCount = 0, useHeights = true } = exceptions || {};
  return { aboveBody, extraStartCount, useHeights };
};

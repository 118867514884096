import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { getCurrentBrand } from '@/utils/getCurrentBrand';
import { log } from 'lib/data/compute/ads/helpers';

import { getOcelotConfig } from 'lib/configs/ocelot';
import { AdsMeta, Meta } from 'lib/labrador/types';
import { getEnvironment, getVersionNo } from 'lib/labrador/utils';
import Head from 'next/head';
import Script from 'next/script';
import { isUndefined } from 'typesafe-utils';

export interface StandaloneAdTemplateProps extends StandaloneComponentProps {
  pageMeta?: Meta;
  adsData?: AdsMeta;
}

export const StandaloneAdTemplate: StandaloneComponent<StandaloneAdTemplateProps> = ({
  pageMeta = {},
  adsData = {},
}) => {
  const showAds = !(pageMeta?.isNativeAd || pageMeta?.hideAds);
  if (!showAds) {
    return null;
  }

  const preConfig = adsData.adsPreConfig || {};
  const demandManagerUrl = preConfig?.demandManagerUrl || 'https://micro.rubiconproject.com/prebid/dynamic/13012.js';
  const defaultEnableDemandManager = true;
  const enableDemandManager = isUndefined(preConfig?.enableDemandManager)
    ? defaultEnableDemandManager
    : preConfig.enableDemandManager === 'true';
  const enableAdnami = preConfig?.enableAdnami === 'true';
  const isOcelotEnvProd = getEnvironment() === 'prod';
  const isAdsCdnProd = isOcelotEnvProd && process.env.OCELOT_AD_CDN_ENV !== 'stage';
  const latestAdsStable = `3.0.13`;
  const adsBundleProd = 'https://cdn.allermedia.se/se-aller-ads/prod/';
  const adsBundleStage = 'https://cdn-dev.allermedia.se/se-aller-ads/stage/';
  const basePath = isAdsCdnProd ? adsBundleProd : adsBundleStage;
  const adsManagerPath = `${basePath}${preConfig?.siteAdsBundleVersions?.bundleVersion || latestAdsStable}/aller-ads.bundle.min.js`;

  log('adsManagerPath', adsManagerPath);

  let loadedByAdsLib;
  const ocelotLoad = ['/2.', '/1.'].some((majorVersion) => adsManagerPath.includes(majorVersion));
  if (!ocelotLoad) {
    loadedByAdsLib = {
      demandManager: true,
      config: true,
    };
  }

  const currentAdsScriptVersion = getVersionNo(adsManagerPath);
  const variant = '07';
  const cacheBuster = `v=${currentAdsScriptVersion}-${variant}`;
  const demandManagerPath = `${demandManagerUrl}?${cacheBuster}`;

  const { slug, host } = getCurrentBrand();
  const seenthisPath = 'https://video.seenthis.se/public/patrik/high-impact.min.js';
  const adnamiPath = 'https://macro.adnami.io/macro/spec/adsm.macro.747590aa-4758-4281-839e-4b9b65def16a.js';
  const gptPath = 'https://securepubads.g.doubleclick.net/tag/js/gpt.js';
  const adsManagerUrl = `${adsManagerPath}?${cacheBuster}`;
  const adnamiConfPath = `/common/js/ads/adnami-conf.js`;


  const adsUniqueId = adsData.adsUniqueId || '';
  const topAdTarget = `top_ad-${adsUniqueId}`;

  const preload = getOcelotConfig('ads.preload.enabled');

  log('#### showAds', showAds, pageMeta?.hideAds);

  const adsConf: string = JSON.stringify({
    pageMeta,
    adsData,
    preConfig,
    siteName: slug,
    hostName: host,
    topAdTarget,
    loadedByAdsLib,
    usePlacementCheck: true,
    useAma: getOcelotConfig('ads.ama.enabled'),
  });

  return (
    <>
      <Head>
        {/* Always preload prebid since it loads async from ads-bundle*/}
        {enableDemandManager && (
          <link
            rel="preload"
            href="https://micro.rubiconproject.com/prebid/dynamic/13012.js"
            as="script"
            referrerPolicy="strict-origin-when-cross-origin"
          />
        )}
        { preload && (
          <>
            {enableAdnami && (
              <>
                <link rel="preload" href={adnamiConfPath} as="script" />
                <link rel="preload" href={adnamiPath} as="script" />
                <link rel="preload" href={seenthisPath} as="script" />
              </>
            )}
            <link rel="preload" href={gptPath} as="script" />
            <link rel="preload" href={adsManagerUrl} as="script" />
          </>
        )}
      </Head>

      {/* Top_ad - High Impact Formats for adnami and seenthis*/}
      {enableAdnami && (
        <>
          <Script id="adnami-ads-conf" src={adnamiConfPath} strategy={ preload ? 'afterInteractive' : 'beforeInteractive' } />
          <Script id="adnami-ads-formats" src={adnamiPath} strategy={ preload ? 'afterInteractive' : 'beforeInteractive' } />
          <Script id="seenthis" src={seenthisPath} strategy={ preload ? 'afterInteractive' : 'beforeInteractive' } />
        </>
      )}

      {/* GoogleAdsManager */}
      <Script id="gpt-initiator" src={`${gptPath}`} strategy={ preload ? 'afterInteractive' : 'beforeInteractive' } />

      {/* Prebid */}
      {!loadedByAdsLib?.demandManager && enableDemandManager && (
        <Script id="demand-manager" referrerPolicy="strict-origin-when-cross-origin" src={demandManagerPath} />
      )}

      {/* AdsLibrary */}
      <Script
        id="ads-manager"
        src={`${adsManagerPath}?${cacheBuster}`}
        strategy={ preload ? 'afterInteractive' : 'beforeInteractive' }
        {...(loadedByAdsLib ? { 'data-ads-conf': adsConf } : {})}
      />
    </>
  );
};
